import React, { useEffect } from 'react';
import { Menu } from "antd";
import './style.scss';
import { useDispatch } from 'react-redux';
import { login, logout } from '../../features/authSlice';
import { useSelector } from 'react-redux';

import {
  EnvironmentOutlined,
  GroupOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  InfoOutlined,
  UserOutlined,
  FundProjectionScreenOutlined,
  FileTextOutlined,
  BarChartOutlined,
  DiffOutlined,
  PartitionOutlined,
  SwapOutlined,
  DatabaseOutlined,
  DashboardOutlined,
  ArrowRightOutlined

} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { fetchMe } from '../../features/userSlice';

export default function Sidebar() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);



  const items = [
    { key: '/', label: <Link to={`/`}>Home</Link>, icon: <HomeOutlined /> },
    {
      key: 'data',
      label: <strong>Data Tools</strong>,
      icon: <DatabaseOutlined />,
      type: 'sub-menu',
      children: [
        { key: '/data_query', label: <Link to={`/data_query`}>Data Query</Link>, icon: <InfoOutlined /> },
        { key: '/aggregation_query' , label: <Link to={`/aggregation_query`}>Aggregation Query</Link>, icon: <InfoOutlined /> },
        { key: '/summary_table', label: <Link to={`/summary_table`}>Summary Table</Link>, icon: <InfoOutlined /> },
        { key: '/growth_rate', label: <Link to={`/growth_rate`}>Growth Rate</Link>, icon: <DiffOutlined /> },
        { key: '/data_overview', label: <Link to={`/data_overview`}>Data Overview</Link>, icon: <BarChartOutlined /> },
        { 
          key: '/data_first_last_year', 
          label: <span style={{ color: 'gray', cursor: 'not-allowed' }}>Data First-Last Year</span>, 
          icon: <DiffOutlined /> 
        },
        { key: '/php_data_comparator', label: <Link to={`/php_data_comparator`}>PHP Comparator</Link>, icon: <SwapOutlined /> },
        user.role == 99 && { key: '/data-matching', label: <Link to={`/data-matching`}>Data Matching</Link>, icon: <ArrowRightOutlined /> },
        user.role !== 2 && { key: '/csv_uploader', label: <Link to={`/csv_uploader`}>CSV Uploader</Link>, icon: <FileTextOutlined /> },
      ],
    },
    {
      key: 'metadata',
      label: <strong>Metadata Tools</strong>,
      icon: <DashboardOutlined />,
      type: 'sub-menu',
      children: [
        { key: '/categories', label: <Link to={`/categories`}>Categories</Link>, icon: <GroupOutlined /> },
        { key: '/countries', label: <Link to={`/countries`}>Countries</Link>, icon: <EnvironmentOutlined /> },
        { key: '/concordance_table', label: <Link to={`/concordance_table`}>Concordance Table</Link>, icon: <PartitionOutlined /> },
        { key: '/region_table', label: <Link to={`/region_table`}>Region Table</Link>, icon: <PartitionOutlined /> },
        { key: '/indicators', label: <Link to={`/indicators`}>Indicators</Link>, icon: <InfoOutlined /> },
        { key: '/units', label: <Link to={`/units`}>Units</Link>, icon: <FundProjectionScreenOutlined /> },
        { key: '/source', label: <Link to={`/source`}>Source</Link>, icon: <InfoOutlined /> },
        { key: '/manage_aggregations', label: <Link to={`/manage_aggregations`}>Manage Aggregations</Link>, icon: <InfoOutlined /> },
      ],
    },
    /*{ key: '/indicators_mapping', label: <Link to={`/indicators_mapping`}>Indicators Mapping</Link>, icon: <PartitionOutlined /> }, */
    /*{ key: '/external_mappings', label: <Link to={`/external_mappings`}>External Mappings</Link>, icon: <LinkOutlined /> },*/
    user.role === 99 && { key: '/users', label: <Link to={`/users`}>Users</Link>, icon: <UserOutlined /> },
    user.role === 99 && { key: '/log_viewer', label: <Link to={`/log_viewer`}>Log Viewer</Link>, icon: <InfoCircleOutlined /> },
    { key: '/about', label: <Link to={`/about`}>About</Link>, icon: <InfoCircleOutlined /> },
    { key: 'logout', label: `Logout (${user.username})`, icon: <UserOutlined /> },
  ].filter(x => x !== null);
  const normalizePath = pathname => `/${pathname.split('/')[1]}`;

  const selectedKeys = items
    .flatMap(item => item.children ? item.children.map(child => child.key) : item.key)
    .filter(key => key === normalizePath(pathname));
  
  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <Menu theme="dark" mode="inline" defaultOpenKeys={['data']} selectedKeys={selectedKeys} items={items} onClick={({ key }) => {
      if (key === 'logout') {
        handleLogout();
      }
    }} />
  );
}