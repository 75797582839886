import React, { useRef, useState, useEffect, useMemo } from 'react';
import { LineOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { createFilterOptions } from './Utils';
import { formatDataTableValue } from './Utils';
import './datatable.css';
import { IndicatorShowExternal } from './modals/IndicatorShowExternal';

export default function SummaryDataTable({
  columns,
  data,
  loading = false,
  widths,
  calculatePercentage = false,
  percentageCols,
  className,
  decimalSymbol,
  decimalPlaces,
  colYearView = false,
  showAll = false,
  yearRange = [],
  yearOrder = "Descending",
  showGrowthRate = false,
  showIndexColumn = true
}) {
  const tableWrapperRef = useRef(null);
  const [logData, setLogData] = useState(data);
  const countriesState = useSelector((state) => state.countries);
  const indicatorsState = useSelector((state) => state.indicators);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 250,
    position: ["topLeft", "bottomLeft"],
    pageSizeOptions: ["10", "50", "100", "250"]
  });

  const countryFilterOptions = useMemo(() => createFilterOptions(logData, 'c_name'), [logData]);
  const indicatorFilterOptions = useMemo(() => createFilterOptions(logData, 'ind_name'), [logData]);
  const unitFilterOptions = useMemo(() => createFilterOptions(logData, 'u_name'), [logData]);
  const categoryFilterOptions = useMemo(() => createFilterOptions(logData, 'cat_name'), [logData]);

  // Define default columns
  const defaultColumns = [];

  // Generate year columns
  const yearColumns = yearRange.map(year => ({
    title: year.toString(),
    dataIndex: year.toString(),
    key: year.toString(),
    width: widths?.[year.toString()] || 'auto', // Use widths parameter
  }));

  if (yearOrder === "Descending") {
    yearColumns.reverse();
  }

  // Filter and create final columns
  const finalColumns = [
    ...defaultColumns,
    ...columns
      .filter(col => !['id', 'indicator', 'year', 'world'].includes(col))
      .map(col => ({
        title: typeof col === 'string' ? col.charAt(0).toUpperCase() + col.slice(1) : col.title.charAt(0).toUpperCase() + col.title.slice(1),
        dataIndex: typeof col === 'string' ? col : col.dataIndex,
        key: typeof col === 'string' ? col : col.key,
        width: widths?.[col] || 'auto', // Adjust width based on widths parameter
      })),
    ...colYearView ? yearColumns : []
  ];
  

  useEffect(() => {
    if (data) {
      console.log(data);
      let updatedData = data.map((element, index) => {
        element.key = index;
        if (columns.includes("indicator")) {
          element.indicator = indicatorsState.value[element.ind_code];
        }
        return element;
      });

      if (calculatePercentage) {
        updatedData = updatedData.map(element => {
          const percentage = ((element.d_value_last - element.d_value_first) / element.d_value_first) * 100;
          element.percentage = percentage.toFixed(2);
          return element;
        });
      }
      
      console.log("Updated Data",updatedData);
      console.log("Columns", columns);
      setLogData(updatedData);
    }
  }, [data]);

  const handleIndicatorClick = (indicatorCode) => {
    setSelectedIndicator(indicatorsState.value[indicatorCode]);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedIndicator(null);
  };

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
  };

  const renderPercentageDifference = (percentageDifference) => {
    let color = 'black';
    let Icon = LineOutlined;
    if (percentageDifference > 0) {
      color = 'green';
      Icon = UpOutlined;
    } else if (percentageDifference < 0) {
      color = 'red';
      Icon = DownOutlined;
    }
    return percentageDifference !== 0 ? (
      <span style={{ color, fontWeight: "bold", float: "right" }}>
        ({percentageDifference}%) <Icon />
      </span>
    ) : (
      <span style={{ color, fontWeight: "bold", float: "right" }}>
        ({percentageDifference}%) <Icon />
      </span>
    );
  };

  function sortValue(colName, a, b, sortOrder = "ascend") {
    let direction = sortOrder === "ascend" ? 1 : -1;
    const isANull = a[colName] === null || a[colName] === undefined;
    const isBNull = b[colName] === null || b[colName] === undefined;

    if (isANull && isBNull) {
      return 0;
    } else if (isANull) {
      return direction;
    } else if (isBNull) {
      return -direction;
    }

    const valA = parseFloat(a[colName]);
    const valB = parseFloat(b[colName]);
    return valA - valB;
  }

  const transformedColumns = finalColumns.map(column => ({
    ...column,
    sorter: {
      compare: (a, b, sortOrder) => {
        if (column.dataIndex === "percentage") {
          return a.percentage - b.percentage;
        } else if (["d_value", "d_value_first", "d_value_last", "d_value_live", "d_value_test", "d_date"].includes(column.dataIndex)) {
          return sortValue(column.dataIndex, a, b, sortOrder);
        } else if (yearRange.includes(parseInt(column.dataIndex))) {
          return sortValue(column.dataIndex, a, b, sortOrder);
        } else if (typeof a[column.dataIndex] === "number") {
          return a[column.dataIndex] - b[column.dataIndex];
        } else {
          return a[column.dataIndex].localeCompare(b[column.dataIndex]);
        }
      }
    },
    filterMultiple: true,
    render: (text, record) => {
      switch (column.dataIndex) {
        case "id":
          return record.id;
        case "indicator":
          return (
            <a
              href="#"
              onClick={() => handleIndicatorClick(record.ind_code)}
              style={{ color: 'blue', textDecoration: 'underline' }}
            >
              {record.indicator}
            </a>
          );
        case "year":
          return record.year;
        case "world":
          return formatDataTableValue(record.world, decimalSymbol, decimalPlaces);
        default:
          return text;
      }
    }
  }));

  const tableRef = useRef(null);
  const scrollableWrapperRef = useRef(null);
  const isScrolling = useRef(false);
  const startX = useRef(0);
  const startY = useRef(0);
  const scrollLeft = useRef(0);
  const scrollTop = useRef(0);
  const scrollMultiplier = 1.5;

  const onMouseDown = (e) => {
    isScrolling.current = true;
    startX.current = e.pageX;
    startY.current = e.pageY;
    scrollLeft.current = scrollableWrapperRef.current.scrollLeft;
    scrollTop.current = scrollableWrapperRef.current.scrollTop;
    document.body.style.cursor = 'grabbing';
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    console.log("Mouse down:", { startX: startX.current, startY: startY.current, scrollLeft: scrollLeft.current, scrollTop: scrollTop.current });
  };

  const onMouseMove = (e) => {
    if (!isScrolling.current) return;
    e.preventDefault();
    const x = e.pageX;
    const y = e.pageY;
    const walkX = (x - startX.current) * scrollMultiplier;
    const walkY = (y - startY.current) * scrollMultiplier;

    scrollableWrapperRef.current.scrollLeft = scrollLeft.current - walkX;
    scrollableWrapperRef.current.scrollTop = scrollTop.current - walkY;
    console.log("Mouse move:", { x, y, walkX, walkY, newScrollLeft: scrollableWrapperRef.current.scrollLeft, newScrollTop: scrollableWrapperRef.current.scrollTop });
  };

  const onMouseUp = () => {
    if (!isScrolling.current) return;
    isScrolling.current = false;
    document.body.style.cursor = 'default';
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
    console.log("Mouse up");
  };

  useEffect(() => {
    const scrollableWrapper = scrollableWrapperRef.current;
    scrollableWrapper.addEventListener('mousedown', onMouseDown);
    return () => {
        scrollableWrapper.removeEventListener('mousedown', onMouseDown);
    };
  }, []);

  return (
    <div className="tableDiv">
        <div className="scrollable-wrapper" ref={scrollableWrapperRef} style={{ maxHeight: '80vh' }}>
          <div className="scrollable-content" ref={tableRef} style={{ marginTop: '-50px' }}>
            <Table
              className={`dataTable ${className}`}
              columns={transformedColumns}
              dataSource={logData}
              loading={loading}
              pagination={pagination}
              onChange={handleTableChange}
              scroll={{ x: 'max-content' }}
              rowClassName="table-row"
            />
          </div>
        </div>

        {selectedIndicator && (
          <IndicatorShowExternal
            visible={modalVisible}
            onCancel={handleModalCancel}
            indicator={selectedIndicator}
          />
        )}
    </div>
  );
}
